import * as React from 'react'
import { useState } from 'react'
import Layout from '/src/components/layout'
import StationerKarta from '/src/components/stationer-karta'

const IndexPage = () => {
	const [filters, setFilters] = useState({})

	return (
		<Layout displayFilterToggle={true} setFilters={filters => setFilters(filters)} displayPageTitle={false}>
			<StationerKarta filters={filters}></StationerKarta>
		</Layout>
	)
}

export default IndexPage