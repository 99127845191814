import * as React from 'react'
import { useState, useEffect } from 'react'
import { navigate } from "gatsby"
import { stationsMap, mapPositionButton, stationsCount, popupTitle, popupAddress, popupConnectors, popupNetwork, popupReadmore, popupFavorite } from '/src/styles/stationer-karta.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt, faLocationArrow, faSpinner, faStar } from '@fortawesome/free-solid-svg-icons'

import { MapContainer, TileLayer, Circle, CircleMarker, Popup, ZoomControl, useMapEvents, useMap } from 'react-leaflet'
import { GeoSearchControl, BingProvider } from 'leaflet-geosearch'
import '/node_modules/leaflet-geosearch/dist/geosearch.css'

import { connectorTypeIDs } from '/src/components/support.js'


function MyComponent() {
    const [myPositionCoordinates, setPosition] = useState([60.851497936459744, 14.802226395570694])
    const [myPositionAccuracy, setAccuracy] = useState(0);

    const map = useMapEvents({
        click: () => {
            //map.locate()
        },
        locationfound: (location) => {
            //console.log('location found:', location)
            var zoomLevel = 15
            /*
            if(location.accuracy > zoomLevel) {
                zoomLevel = location.accuracy;
            }
            */

            map.setView(location.latlng, zoomLevel)

            setPosition(location.latlng)
            setAccuracy(location.accuracy)
        },
        locationerror: (error) => {
            console.log('location ERROR:', error)
        },
        moveend: (prop) => {
            if(prop.target._lastCenter && prop.target.getCenter().lat && prop.target.getCenter().lng && prop.target.getZoom()) {
                if(typeof window !== 'undefined') {
                    window.history.pushState(null, null, "?lat="+prop.target.getCenter().lat+"&lng="+prop.target.getCenter().lng+"&zoom="+prop.target.getZoom());
                }
                /*navigate(
                    "?lat="+prop.target.getCenter().lat+"&lng="+prop.target.getCenter().lng+"&zoom="+prop.target.getZoom(),
                    { replace: true }
                )*/
            }
        },
    })

    function locate() {
        map.locate()
    }

    return (<>
        <div className={mapPositionButton} onClick={locate}>
            <FontAwesomeIcon icon={faLocationArrow} />
        </div>
        <Circle center={myPositionCoordinates} radius={myPositionAccuracy} key='myPos' attribution='<a href="/om/villkor">villkor</a>&nbsp;<p><svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" style="height:10px; fill:rgb(255, 173, 245);"><circle cx="5" cy="5" r="5"/></svg> Destinationsladdning</p><p><svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" style="height:10px; fill:rgb(228, 94, 210);"><circle cx="5" cy="5" r="5"/></svg> Snabbladdning (> 50 kW)</p><p><svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" style="height:10px; fill:rgb(166, 12, 143);"><circle cx="5" cy="5" r="5"/></svg> Supersnabbladdning (> 150 kW)</p>'></Circle>
    </>)
}

const StationerKarta = ({filters}) => {
    
    var urlParams = new URLSearchParams('');
    
    if(typeof window !== 'undefined') {
        urlParams = new URLSearchParams(window.location.search)
    }

    const [mapPosition, setMapPosition] = useState([urlParams.get('lat') ? urlParams.get('lat') : 60.851497936459744, urlParams.get('lng') ? urlParams.get('lng') : 14.802226395570694])
    const [mapZoom, setMapZoom] = useState(urlParams.get('zoom') ? urlParams.get('zoom') : 5)

    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState([]);
    var stationsCountStr = Object.keys(data).length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    var tmpFav = []
    if(typeof window !== 'undefined') {
        tmpFav = JSON.parse(localStorage.getItem('favorites')) || []
    }
    const [favoritesList, setFavoritesList] = useState(tmpFav)

    useEffect(() => {
        if(typeof window !== 'undefined') {
            localStorage.setItem('favorites', JSON.stringify(favoritesList))
        }
    }, [favoritesList])

    const handleFavoriteChange = event => {
        const stationID = parseInt(event.currentTarget.getAttribute("data-stationid"))
        
        if(favoritesList.includes(stationID)) { //[event.currentTarget.getAttribute("data-stationid")]) {
            // Remove
            setFavoritesList(favoritesList.filter(item => item !== stationID))
            event.target.style.color = '#E0E0E0'
        }
        else {
            // Add
            setFavoritesList([
                ...favoritesList,
                stationID
            ]);
            event.target.style.color = '#FFD700'
        }
    };



    const getData = () => {
        setIsLoading(true)
        var url = `${process.env.GATSBY_API_URL}`+'/stations'
        var cache = 'force-cache'

        var filterArray = []
        if(filters && Object.keys(filters).length > 0) {

            // UTTAG
            if(filters['connector_ccs'] && filters['connector_ccs'] === true) {
                if(filters['connector_ccs_min']) {
                    filterArray.push('connector_ccs_min='+filters['connector_ccs_min'])
                }
                else {
                    filterArray.push('connector_ccs_min=1')
                }
            }

            if(filters['connector_type2'] && filters['connector_type2'] === true) {
                if(filters['connector_type2_min']) {
                    filterArray.push('connector_type2_min='+filters['connector_type2_min'])
                }
                else {
                    filterArray.push('connector_type2_min=1')
                }
            }

            if(filters['connector_chademo'] && filters['connector_chademo'] === true) {
                if(filters['connector_chademo_min']) {
                    filterArray.push('connector_chademo_min='+filters['connector_chademo_min'])
                }
                else {
                    filterArray.push('connector_chademo_min=1')
                }
            }

            if(filters['connector_schuko'] && filters['connector_schuko'] === true) {
                if(filters['connector_schuko_min']) {
                    filterArray.push('connector_schuko_min='+filters['connector_schuko_min'])
                }
                else {
                    filterArray.push('connector_schuko_min=1')
                }
            }

            if(filters['connector_cee'] && filters['connector_cee'] === true) {
                if(filters['connector_cee_min']) {
                    filterArray.push('connector_cee_min='+filters['connector_cee_min'])
                }
                else {
                    filterArray.push('connector_cee_min=1')
                }
            }


            // EFFEKT
            if(filters['power_min']) {
                filterArray.push('power_min='+filters['power_min'])
            }
            if(filters['power_max']) {
                filterArray.push('power_max='+filters['power_max'])
            }


            // PRIS
            if(filters['price_min']) {
                filterArray.push('price_min='+filters['price_min'])
            }
            if(filters['price_max']) {
                filterArray.push('price_max='+filters['price_max'])
            }


            // PRISMODELL
            if(filters['pricingmodel']) {
                filterArray.push('pricingmodel='+filters['pricingmodel'])
            }


            // BETALSÄTT
            Object.keys(filters).forEach(e => {
                if(e.startsWith('paymentmethod_')) {
                    if(filters[e] === true) {
                        filterArray.push(e+'=1')
                    }
                }
            })


            // NÄTVERK
            var networks = []
            Object.keys(filters).forEach(e => {
                if(e.startsWith('network_')) {
                    if(filters[e] === true) {
                        networks.push(e.replace('network_', ''))
                    }
                }
            })
            if(networks && Object.keys(networks).length > 0) {
                filterArray.push('networks='+networks.join(','))
            }

            // NÄRHETEN
            /*
                if(filters['connector_cee'] && filters['connector_cee'] === true) {
                    if(filters['connector_cee_min']) {
                        filterArray.push('connector_cee_min='+filters['connector_cee_min'])
                    }
                    else {
                        filterArray.push('connector_cee_min=1')
                    }
                }
            */


            // LAND
            var countries = []
            Object.keys(filters).forEach(e => {
                if(e.startsWith('country_')) {
                    if(filters[e] === true) {
                        countries.push(e.replace('country_', ''))
                    }
                }
            })
            if(countries && Object.keys(countries).length > 0) {
                filterArray.push('countries='+countries.join(','))
            }


            url += '/filter?'+filterArray.join('&')
            cache = 'default'
        }

        fetch(url, {
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            cache: cache,
        })
        .then(function(response) {
            //console.log(response)
            return response.json();
        })
        .then(function(myJson) {
            //console.log(myJson);
            setData(myJson)
            setIsLoading(false)
        });
    }

    useEffect(() => {
        getData()
    }, [filters])



    const SearchField = () => {
        const provider = new BingProvider({
            params: {
                key: 'AtMRWTLLBgdY-tHJIavxhqTXPz5nKE4afzjNZ_IqVquioVE1JXxXcOVs45WlfgKK',
            },
        });

        // @ts-ignore
        const searchControl = new GeoSearchControl({
            provider: provider,
            notFoundMessage: 'Adressen hittades inte!',
            position: 'bottomright',
        });

        const map = useMap();
        useEffect(() => {
                map.addControl(searchControl);
                return () => map.removeControl(searchControl);
        }, []);

        return null;
    };



    return (<>
        <div className={stationsCount}>
            <p style={{display: isLoading === true ? "inline" : "none" }}><FontAwesomeIcon icon={faSpinner} size="1x" /> Laddar...</p>
            <p style={{display: isLoading === true ? "none" : "inline" }}>Visar <b>{stationsCountStr} st</b> stationer</p>
        </div>

        <MapContainer className={stationsMap} center={mapPosition} zoom={mapZoom} preferCanvas={true} zoomControl={false}>
            <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <ZoomControl position='bottomright' />
            <MyComponent className={mapPositionButton} />
            <SearchField position='bottomright' />
            
            {
                data && Object.keys(data).length > 0 && Object.keys(data).map((key) => {
                    var circleColor = 'rgb(255, 173, 245)'
                    if(data[key].highestKw >= 150) {
                        circleColor = 'rgb(166, 12, 143)'
                    }
                    else if(data[key].highestKw >= 50) {
                        circleColor = 'rgb(228, 94, 210)'
                    }

                    var tmpAddressStr = data[key].street;
                    if(data[key].zipcode != null) {
                        tmpAddressStr += ', '+ data[key].zipcode.toString().substring(0, 3) +' '+ data[key].zipcode.toString().substring(3, 5);
                    }
                    if(data[key].city != null) {
                        tmpAddressStr += ' ' + data[key].city;
                    }

                    return (
                        <CircleMarker center={[data[key].latitude, data[key].longitude]} key={data[key].id} color='#FFF' weight='2' fillColor={circleColor} fillOpacity='0.8'>
                            <Popup>
                                <FontAwesomeIcon icon={faStar} className={popupFavorite} style={{color: favoritesList.includes(data[key].id) ? '#FFD700' : '#E0E0E0'}} data-stationid={data[key].id} onClick={handleFavoriteChange} />
                                <p className={popupNetwork}><img src={data[key].network.logoURL} alt={data[key].network.name} /></p>
                                <p className={popupTitle}>{data[key].name}</p>
                                <p className={popupAddress}>{tmpAddressStr}</p>
                                <table className={popupConnectors}>
                                    <tbody>
                                        {
                                            data[key].connectors && Object.keys(data[key].connectors).length > 0 && data[key].connectors.map((row) => {
                                                return (
                                                    <tr>
                                                        <td><b>{row['amount']} st</b></td>
                                                        <td>{row['kW'] ? row['kW']+' kW' : ''} </td>
                                                        <td><img src={"/connectors/"+row['type']+".svg"} alt="Ikon" /></td>
                                                        <td>{connectorTypeIDs[row['type']] ? connectorTypeIDs[row['type']] : row['type']}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                <a href={'/stations/'+data[key].id} target="_blank" rel="noreferrer">
                                    <p className={popupReadmore}>
                                        <b>Läs mer</b> &nbsp;<FontAwesomeIcon icon={faExternalLinkAlt} />
                                    </p>
                                </a>
                            </Popup>
                        </CircleMarker>
                    )
                })
            }

        </MapContainer>
    </>)
}

export default StationerKarta